import React, {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Grid, Box, Snackbar, Button} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {useHistory} from 'react-router-dom'
import {ResultContext} from '../context/ResultContext'
import $t from '@card-statements/common/transactions'
import $tr from '@card-statements/common/transactionReviews'
import {formatTimestamp, priceFormat} from '../utils'
import {Subtitle} from '../components/Typography'
import {GridList} from '../components/GridList'
import {PageSwitcherHeader} from '../components/PageSwitcherHeader'
import {PropTypes} from 'prop-types'

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
  },
  link: {
    textDecoration: 'none',
  },
  listHolder: {
    height: '100%',
    display: 'block',
  },
  item: {
    'textAlign': 'left',
    'whiteSpace': 'nowrap',
    'cursor': 'pointer',
    'borderBottom': '1px solid rgba(220, 220, 220, 1)',
    'borderLeft': '1px solid rgba(220, 220, 220, 1)',
    'borderRight': '1px solid rgba(220, 220, 220, 1)',
    'padding': theme.spacing(1),
    '&:first-child': {
      borderTop: '1px solid rgba(220, 220, 220, 1)',
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  field: {
    padding: 4,
    fontSize: 14,
  },
  propertyName: {
    fontWeight: 'bold',
    marginRight: '5px',
  },
  error: {
    backgroundColor: '#ffe9ec',
    padding: theme.spacing(1),
    fontFamily: 'Monospace',
  },
}))

const HomePage = (props) => {
  const {data, setWithUserChooser} = props
  const {
    transactions,
    allNotRejectedTransactionReviews,
    counterpartyExclusion,
    isFinanceMember,
} = data
  const reviewUuids = new Set(allNotRejectedTransactionReviews.map((s) => s[$tr.transaction_uuid]))
  const classes = useStyles()
  const history = useHistory()
  const {result, clearResult} = useContext(ResultContext)
  const toastSeverity = result && result.success ? 'success' : 'error'
  const toastTitle = (result && result.success ? 'Invoice successfully submitted.' : 'Invoice submit unsuccessful. Errors:')
  const toastDuration = (result && result.success ? 3000 : 5000)
  const toastClose = () => {
    clearResult()
  }
  const transactionsToShow = transactions.filter((t) => {
    return !reviewUuids.has(t[$t.uuid]) &&
    // !['fee', 'atm'].includes(t[$t.type]) &&
    !RegExp(counterpartyExclusion[t[$t.card]]).test(t[$t.counterparty]) &&
    t[$t.reversed] === false
  })
  const excludePageLinks = ['/home'] // exclude self
  if (!isFinanceMember) excludePageLinks.push('/finance-dashboard')
  setWithUserChooser(true)

  return (<>
    <Box className={classes.mainGrid}>
      <PageSwitcherHeader exclude={excludePageLinks}>
        <Subtitle>Select transaction</Subtitle>
      </PageSwitcherHeader>
      <GridList>
        {transactionsToShow.map((row) => {
          const query = new URLSearchParams({transaction: row[$t.uuid]})
          const hasRejectionReason = row[$t.rejectionReason] !== undefined
          return (
            <Grid key={row[$t.uuid]} container>
              <Grid
                item
                container
                md={12} lg={11}
              >
                <Grid key={1} item xs={6} md={2}>
                  Card:
                  <span>
                    {row[$t.card]}
                  </span>
                </Grid>
                <Grid key={2} item xs={6} md={2}>
                  Bank:
                  <span>
                    {row[$t.bank]}
                  </span>
                </Grid>
                <Grid key={3} item xs={6} md={2}>
                  Date:
                  <span>
                    {formatTimestamp(row[$t.date])}
                  </span>
                </Grid>
                <Grid key={4} item xs={6} md={2}>
                  Amount:
                  <span>
                    {priceFormat(row[$t.amountCurrency])} {row[$t.currency]}
                  </span>
                </Grid>
                <Grid key={5} item xs={12} md={4}>
                  Counterparty:
                  <span>
                    {row[$t.counterparty]}
                  </span>
                </Grid>
                <Grid key={6} item xs={12} md={3}>
                  UUID:
                  <span>
                    {row[$t.uuid]}
                  </span>
                </Grid>
                <Grid key={7} item xs={12} md={3}>
                  Reference:
                  <span>
                    {row[$t.reference]}
                  </span>
                </Grid>
                <Grid key={8} item xs={12} md={6}>
                  Message:
                  <span>
                    {row[$t.description]}
                  </span>
                </Grid>
                {hasRejectionReason && <Grid key={9} item xs={12} md={6}>
                  <span style={{color: 'red', marginLeft: 0}}>
                  Rejection reason:
                    {` ${row[$t.rejectionReason]}`}
                  </span>
                </Grid>
                }
              </Grid>
              <Grid item md={12} lg={1}>
                <Button variant="contained" onClick={() => history.push(`/add-statement?${query.toString()}`)}>Add details</Button>
              </Grid>
            </Grid>
          )
        })}
      </GridList>
    </Box>
    <Snackbar
      anchorOrigin={{vertical:'top', horizontal:'center'}}
      open={!!result}
      onClose={toastClose}
      autoHideDuration={toastDuration}
      transitionDuration={0}
    >
      <Alert onClose={toastClose} severity={toastSeverity}>
        <AlertTitle>{toastTitle}</AlertTitle>
        {(result && !result.success && result.error
          ? (<div className={classes.error}>{result.error}</div>)
          : null)}
      </Alert>
    </Snackbar>
  </>)
}

HomePage.propTypes = {
  data: PropTypes.object,
  setWithUserChooser: PropTypes.func,
}

export {HomePage}
