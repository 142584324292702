import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

export const setupAuthentication = () => {
  const rootElement = document.getElementById('root')

  const redirectToLogin = () => {
    window.location.replace(process.env.REACT_APP_LOGIN_URL)
  }

  const renderError = (message) => {
    ReactDOM.render((
      <pre>{message}</pre>
    ), rootElement)
  }

  axios.interceptors.response.use(undefined, (error) => {
    const code = error.response.status

    if (code === 401) {
      redirectToLogin()
    } else {
      const errorMessage =
        code === 403 ? 'Missing required permissions' : `Unexpected error: 
${JSON.stringify(error.response, null, 4)}`

      renderError(errorMessage)
    }

    return Promise.reject(error)
  })
}
