import React, {useState, useMemo, useContext} from 'react'
import {useLocation, Link} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {Typography, FormControlLabel, Checkbox, Button, TextField,
  Grid, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle} from '@material-ui/core'
import $t from '@card-statements/common/transactions'
import {sendStatementFormData} from '../communication'
import {Subtitle} from '../components/Typography'
import {Header} from '../components/Header'
import {DataEntryTable} from '../components/DataEntryTable'
import DropdownCenter from '../components/DropdownCenter'
import {formatTimestamp, priceFormat, decimalFloat} from '../utils'
import {LoadingContext} from '../context/LoadingContext'
import {ResultContext} from '../context/ResultContext'
import {useDropzone} from 'react-dropzone'
import _ from 'lodash'
import {allowedStatementAttachments} from '@card-statements/common/constants'
import {PropTypes} from 'prop-types'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  hidden: {
    display: 'none',
  },
  textField: {
    width: '340px',
  },
  transaction: {
    textAlign: 'center',
  },
  dialog: {
    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
  error: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
  },
  dropzone: {
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    opacity: 0,
  },
  dropzoneActive: {
    zIndex: 100,
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    opacity: 0,
  },
  dropFileOverlay: {
    '&': {
      display: 'flex',
      position: 'fixed',
      overflow: 'hidden',
      opacity: 0.7,
      backgroundColor: '#000000',
      color: '#FFFFFF',
      fontSize: '50pt',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 99,
    },
  },
}))
const AddStatementPage = (props) => {
  const {data, forceFetch, setWithUserChooser} = props
  const {transactions, inNameOf, glAccounts, centersAllowedCombinations,
    jiraIdsActiveEmployees, availableCostTypes, reportingProjects} = data
  const {startLoading, stopLoading} = useContext(LoadingContext)
  const {setResult} = useContext(ResultContext)
  const {search} = useLocation()
  const query = new URLSearchParams(search)
  const query_uuid = query.get('transaction')
  const transaction = transactions.find((t) => t[$t.uuid] === query_uuid)
  if (!transaction) return <div>Select transaction</div>
  const totalCost = transaction[$t.amountCurrency]
  const totalCostAbs = Math.abs(totalCost)
  const classes = useStyles()
  // data
  const taxablePrefill = query.get('isTaxable') != null ? query.get('isTaxable') === 'true' : null
  const [taxable, setTaxable] = useState(taxablePrefill ?? true)
  const [reinvoicing, setReinvoicing] = useState(false)
  const [reinvoicingProject, setReinvoicingProject] = useState(query.get('reinvoicingProject') ?? '')
  const [reinvoicingProjectOptions, setReinvoicingProjectOptions] = useState([])
  const [note, setNote] = useState(query.get('note') ?? '')
  const [files, setFiles] = useState([])
  const [filesErrors, setFilesErrors] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isErrorOpen, setIsErrorOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const currencyOptions = useMemo(() => [transaction[$t.currency], '%'], [transaction])

  const isJiraIdRequired = (costCenter, subcostCenter) => costCenter === 'Reinvoicing' && subcostCenter === 'People'

  const cacheCenterSplit = () => {
    // we assume if there is one center there is complete information
    const centerSplit = JSON.parse(query.get('centerSplit')) ?? {}
    const rawRows = _.toPairs(centerSplit)
    if (rawRows?.length === 0) {
        return {
        unit: '%',
        total: 100,
        rows: [],
      }
    }
    const nullCenterRegex = RegExp(/^NULL\//)
    return {
      unit: transaction[$t.currency], // the query contains split always in money
      total: _.sum(rawRows.map(([, value]) => value)),
      rows: rawRows.map(([costCenter, subcostCenter, location, costAccount, account, value]) => ({
        value,
        costCenter: nullCenterRegex.test(costCenter) ? 'NULL' : costCenter,
        subcostCenter: nullCenterRegex.test(subcostCenter) ? 'NULL' : subcostCenter,
        location: nullCenterRegex.test(location) ? 'NULL' : location,
        costAccount: nullCenterRegex.test(costAccount) ? 'NULL' : costAccount,
        costType: nullCenterRegex.test(costAccount) ? 'NULL' : costAccount,
        jiraId: nullCenterRegex.test(costCenter) ? _.split(costCenter, '/')[1] : null,
        account: nullCenterRegex.test(account) ? 'NULL' : account,
        edited: true,
      })),
    }
  }

  const cachedCenterSplit = cacheCenterSplit()
  const [rows, setRows] = useState(cachedCenterSplit.rows.length > 0
    ? cachedCenterSplit.rows
    : [{costCenter: null,
      subcostCenter: null,
      subcostCenterOptions: [],
      location: null,
      locationOptions: [],
      costAccount: null,
      costAccountOptions: [],
      costType: null,
      costTypeOptions: [],
      jiraId: null,
      accounts: [],
      value: 100,
      edited: false}],
  )
  const [currency, setCurrency] = useState(cachedCenterSplit.unit)
  // helper functions / vars
  const expectedSumOfRows = useMemo(() => currency === '%' ? 100 : totalCostAbs, [currency])
/* This doesn't work! 871.88 --> 872, 100.01% No idea why ...
  const currentSumOfRows = useMemo(() => decimalFloat(_.sum(rows.map((row) =>
    isNaN(parseFloat(row.value)) ? 0 : decimalFloat(parseFloat(row.value)))), [rows]))
*/
  const currentSumOfRows = useMemo(() => {
    const s = _.sum(rows.map((row) =>
    isNaN(parseFloat(row.value)) ? 0 : decimalFloat(parseFloat(row.value))))
    const d = decimalFloat(s)
    return d
  }, [rows])

  const getValueError = (value) => {
    if (value === '') {
      return 'Value field empty'
    }
    const floatValue = parseFloat(value)
    if (!RegExp(/^[0-9]*(\.[0-9][0-9]?)?$/).test(value) || isNaN(floatValue)) {
      return 'Value has to be a number rounded to 2 decimal places'
    }
    if (currency === '%') {
      if (floatValue < 0 || floatValue > 100) {
        return 'Value is not a valid percentage'
      }
    } else {
      if (floatValue <= 0) {
        return 'Value has to be positive'
      } else if (floatValue > totalCostAbs) {
        return 'Value is more than total sum'
      }
    }
    return ''
  }
  const checkValueConsistency = () => _.reduce(
    rows.map((r) => getValueError(r.value)),
    (res, curr) => res && (curr === ''),
    true,
  )
  const checkValueSumConsistency = () => currentSumOfRows === expectedSumOfRows
  const checkCostCenterConsistency = () => {
    return rows.filter(
      ({costCenter}) =>
        costCenter === null).length === 0
  }
  const checkSubCostCenterConsistency = () => {
    return rows.filter(
      ({subcostCenter, subcostCenterOptions}) =>
        subcostCenter === null && subcostCenterOptions.length > 0).length === 0
  }
  const checkLocationConsistency = () => {
    return rows.filter(
      ({location, locationOptions}) =>
        location === null && locationOptions.length > 0).length === 0
  }
  const checkCostAccountConsistency = () => {
    return rows.filter(
      ({costAccount, costAccountOptions}) =>
      costAccount === null && costAccountOptions.length > 0).length === 0
  }
  const checkJiraIdConsistency = () => {
    return rows.filter(
      ({costCenter, subcostCenter, jiraId}) =>
        isJiraIdRequired(costCenter, subcostCenter) && jiraId === null).length === 0
  }
  const checkCostTypeConsistency = () => {
    return rows.filter(
      ({costCenter, costAccount, costType, costTypeOptions}) =>
      costTypeOptions.length > 0 &&
      costType === null).length === 0
  }
  const checkCenterDuplicity = () => {
    const seen = []
    const test = rows.filter(
      ({costCenter, subcostCenter, location,costAccount, costType, jiraId}) => {
      const c = `${costCenter}/${subcostCenter}/${location}/${costAccount}/${costType}/${jiraId}`
      if (seen.includes(c)) {
        return true
      } else {
        seen.push(c)
        return false
      }
    })
    return test.length === 0
  }
  const handleTaxableChange = (event) => {
    setTaxable(event.target.checked)
  }
  const handleReinvoicingChange = (event) => {
    setReinvoicing(event.target.checked)
    if (event.target.checked) {
      setReinvoicingProjectOptions(reportingProjects.map(([name, code, status]) => name))
    } else {
      setReinvoicingProjectOptions([])
      setReinvoicingProject('')
    }
  }
  const handleReinvoicingProjectChange = () => (event, newValue) => {
    setReinvoicingProject(newValue)
  }
  const handleNoteChange = (event) => {
    setNote(event.target.value)
  }
  const handleFileChange = (event) => {
    const filesList = event.target.files
    const newFiles = []
    for (let i = 0; i < filesList.length; i++) {
      newFiles.push(filesList[i])
    }
    setFiles(newFiles)
    setFilesErrors([])
  }
  const handleFileDrop = (acceptedFiles) => {
    setFiles(acceptedFiles)
  }
  const sendStatements = async () => {
    startLoading()
    const uuid = transaction && transaction[$t.uuid]
    const centerValueList = rows.map(
      ({costCenter, subcostCenter, location, costAccount, costType, jiraId, value}) => ([
      `${costCenter}/${subcostCenter}/${location}/${costAccount}/${costType}/${jiraId}`,
      // honor the unit chosen by user: % or money:
      parseFloat(value),
    ]))
    const accountValueList = rows.map(
      ({costCenter, subcostCenter, location, costAccount, costType, jiraId, account}) => ([
      `${costCenter}/${subcostCenter}/${location}/${costAccount}/${costType}/${jiraId}`,
      account,
    ]))
    // ensure that the sum is 100% or totalCostAbs
    const total = currency === '%' ? 100 : totalCostAbs
    centerValueList[0][1] = decimalFloat(
      centerValueList[0][1] + total - _.sum(centerValueList.map(([, value]) => value)))
    const centerSplit = _.fromPairs(centerValueList)
    const accountSplit = _.fromPairs(accountValueList)
    const response = await sendStatementFormData(
      currency,
      centerSplit,
      accountSplit,
      taxable,
      reinvoicing,
      reinvoicingProject,
      note,
      files,
      uuid,
      inNameOf,
      transaction[$t.accountCurrency],
      transaction[$t.card],
      transaction[$t.bank],
      transaction[$t.bankAccount],
      transaction[$t.legalEntity],
    )
    if (response.status === 200) {
      await forceFetch()
    }
    setResult(response.data)
    stopLoading()
  }
  const checkAndSendStatements = async (isDialogConfirmed) => {
    if (!checkCostCenterConsistency()) {
      setErrorMessage('Cost Center is always required.')
      setIsErrorOpen(true)
      return
    }
    if (!checkSubCostCenterConsistency()) {
      setErrorMessage('Subcost Center is required.')
      setIsErrorOpen(true)
      return
    }
    if (!checkLocationConsistency()) {
      setErrorMessage('Location is required.')
      setIsErrorOpen(true)
      return
    }
    if (!checkCostAccountConsistency()) {
      setErrorMessage('Cost Account is required.')
      setIsErrorOpen(true)
      return
    }
    if (!checkCostTypeConsistency()) {
      setErrorMessage('Cost Type is required.')
      setIsErrorOpen(true)
      return
    }
    if (!checkJiraIdConsistency()) {
      setErrorMessage('Jira ID is required for Reinvoicing/People.')
      setIsErrorOpen(true)
      return
    }
    if (!checkValueConsistency()) {
      setErrorMessage('All values must be valid.')
      setIsErrorOpen(true)
      return
    }
    if (!checkValueSumConsistency()) {
      setErrorMessage(`Values in rows have to add up to ${expectedSumOfRows} ${currency}.`)
      setIsErrorOpen(true)
      return
    }
    if (reinvoicing && reinvoicingProject === '') {
      setErrorMessage('Reinvoicing statements should have reinvoicing project selected.')
      setIsErrorOpen(true)
      return
    }
    const bigFiles = files.filter((f) => f.size > 10000000)
    if (bigFiles.length > 0) {
      setFilesErrors(bigFiles.map((bf) => `File ${bf.name} is too large.`))
      return
    }
    if (files.length === 0 && taxable === false && isDialogConfirmed !== true) {
      setIsDialogOpen(true)
      return
    }
    if (files.length === 0 && taxable === true) {
      setErrorMessage('Taxable receipt/invoice requires file(s) upload.')
      setIsErrorOpen(true)
      return
    }
    if (!checkCenterDuplicity()) {
      setErrorMessage('Cannot choose same split twice.')
      setIsErrorOpen(true)
      return
    }
    await sendStatements()
  }
  const {getRootProps, isDragActive} = useDropzone({onDrop: handleFileDrop})
  setWithUserChooser(false)

  return (<>
    <Header>
      <div /><Subtitle>Add receipt/invoice</Subtitle><div />
    </Header>
    <div
      {...getRootProps()}
      className={(
        isDragActive
          ? classes.dropzoneActive
          : classes.dropzone
      )}
    />
    {isDragActive && <div className={classes.dropFileOverlay}>
      <div>Drop files</div>
    </div>}
    <Grid container item xs={12} justifyContent="center" spacing={1}>
      {transaction &&
        <Grid container direction="column" justifyContent="center" className={classes.transaction}>
          <Typography display="block">
            {transaction[$t.card]} -{' '}
            {formatTimestamp(transaction[$t.date])}
          </Typography>
          <Typography display="block">
            {priceFormat(totalCost)} {transaction[$t.currency]}
          </Typography>
          <Typography display="block">
            {transaction[$t.counterparty]}
          </Typography>
        </Grid>
      }
      <DataEntryTable
        {...{
          rows,
          setRows,
          currency,
          setCurrency,
          totalCostAbs,
          expectedSumOfRows,
          currentSumOfRows,
          jiraIdOptions: jiraIdsActiveEmployees.sort(),
          availableCostTypes,
          glAccountOptions: glAccounts,
          centersAllowedCombinations,
          currencyOptions,
          getValueError,
        }}
      />
      <Grid container spacing={3}>
        <Grid container item xs={12} justifyContent="center" style={{zIndex: 1}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={taxable}
                onChange={handleTaxableChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Taxable"
          />
        </Grid>
        <Grid container item justifyContent="center" alignItems="center" style={{zIndex: 1}}>
          <Grid item >
            <FormControlLabel
              control={
                <Checkbox
                  checked={reinvoicing}
                  onChange={handleReinvoicingChange}
                  name="reinvoicing"
                  color="primary"
                />
              }
              label="Reinvoicing"
            />
          </Grid>
          <Grid item sm={2}>
            <DropdownCenter
              options={reinvoicingProjectOptions}
              value={reinvoicingProject}
              onChange={handleReinvoicingProjectChange()}
              label="Reinvoicing project"
              disabled={reinvoicingProjectOptions.length === 0}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <TextField
            className={classes.textField}
            label="Description"
            value={note}
            onChange={handleNoteChange}
            multiline
            minRows={4}
            helperText="List items purchased in this transaction"
          />
        </Grid>
        {files.length > 0 &&
          <Grid container justifyContent="center">
            <Typography>Selected files:</Typography>
            {files.map((f, i) => (
              <Grid container justifyContent="center" key={`${i}-${f.name}`}>
                <Typography>
                  {f.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        }
        <Grid container item xs={12} justifyContent="center" spacing={1}>
          <Grid container item xs={12} justifyContent="center">
            <div className={classes.root}>
              <input
                className={classes.hidden}
                id="contained-button-file"
                multiple
                type="file"
                accept={allowedStatementAttachments}
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="default" component="span">
                  select files
                </Button>
              </label>
            </div>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Typography>
              or drag and drop anywhere
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {filesErrors.length > 0 &&
        <Grid item container justifyContent="center">
          {filesErrors.map((fe, i) => (<Grid
            container
            justifyContent="center"
            key={`${i}-${fe}`}
          >
            <Typography className={classes.error}>
              {fe}
            </Typography>
          </Grid>))}
          <Typography className={classes.error}>
            Limit 10MB
          </Typography>
        </Grid>
      }
      <Grid item container xs={12} justifyContent="center">
        <Button variant="contained" color="primary" onClick={checkAndSendStatements}>
          Send receipt/invoice
        </Button>
        &nbsp;
        <Button variant="outlined" color="secondary" component={Link} to="/home">
          cancel
        </Button>
      </Grid>
    </Grid>
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      className={classes.dialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Send this?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You have selected a non-tax expense. Continue if that was intentional.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDialogOpen(false)} color="primary">
          Go back
        </Button>
        <Button onClick={() => checkAndSendStatements(true)} color="primary" autoFocus>
          Continue anyway
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={isErrorOpen}
      onClose={() => setIsErrorOpen(false)}
      className={classes.dialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Invalid action</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsErrorOpen(false)} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </>)
}

AddStatementPage.propTypes = {
  data: PropTypes.object,
  forceFetch: PropTypes.func,
  setWithUserChooser: PropTypes.func,
}

export {AddStatementPage}
