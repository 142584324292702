import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Grid, Box, Typography} from '@material-ui/core'
import $tr from '@card-statements/common/transactionReviews'
import $t from '@card-statements/common/transactions'
import {formatTimestamp} from '../utils'
import {Subtitle} from '../components/Typography'
import {GridList} from '../components/GridList'
import Toast from '../components/Toast'
import {PageSwitcherHeader} from '../components/PageSwitcherHeader'
import {PropTypes} from 'prop-types'

const useStyles = makeStyles({
  mainGrid: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
  },
  link: {
    textDecoration: 'none',
  },
  span: {
    fontWeight: 'bold',
  },
})

const HistoryPage = (props) => {
  const {data, setWithUserChooser} = props
  const {usersTransactionReviews, allTransactions, isFinanceMember} = data
  const sortedTransactionReviews = usersTransactionReviews.map((transactionReview) => ({
    transactionReview,
    transaction: allTransactions.find(
      (t) => t[$t.uuid] === transactionReview[$tr.transaction_uuid],
    ),
  }))
  .sort(
    (a, b) => b.transactionReview[$tr.createdAt].localeCompare(a.transactionReview[$tr.createdAt]),
  )
  const classes = useStyles()

  const [notification, setNotification] = React.useState({severity: undefined, text: undefined})

  const clearNotification = () => setNotification({severity: undefined, text: undefined})

  const excludePageLinks = ['/history'] // exclude self
  if (!isFinanceMember) excludePageLinks.push('/finance-dashboard')
  setWithUserChooser(true)

  return (<>
    <Box className={classes.mainGrid}>
      <PageSwitcherHeader exclude={excludePageLinks}>
        <Subtitle>Recent receipts/invoices</Subtitle>
      </PageSwitcherHeader>
      <GridList>
        {sortedTransactionReviews.map((row) => {
          const transactionReview = row.transactionReview
          const transaction = row.transaction

          const transactionUUID = transactionReview[$tr.transaction_uuid]
          const createdAt = transactionReview[$tr.createdAt]
          const taxable = transactionReview[$tr.taxable]
          const files = transactionReview[$tr.files]
          const centerSplit = transactionReview[$tr.center_split]

          const amountCurrency = transaction ? transaction[$t.amountCurrency] : 'N/A'
          const currency = transaction ? transaction[$t.currency] : 'N/A'
          const reference = transaction ? transaction[$t.reference] : 'N/A'
          const counterparty = transaction ? transaction[$t.counterparty] : 'N/A'
          const description = transaction ? transaction[$t.description] : 'N/A'

          return (
            <Grid
              key={transactionUUID}
              container
            >
            <Grid container item>
              <Grid key={1} item xs={12} md={3} lg={2}>
                UUID:
                <span>
                  {transactionUUID}
                </span>
              </Grid>
              <Grid key={2} item xs={12} md={3} lg={2}>
                Created At:
                <span>
                  {formatTimestamp(createdAt)}
                </span>
              </Grid>
              <Grid key={3} item xs={6} md={3} lg={2}>
                Taxable:
                <span>
                  {taxable}
                </span>
              </Grid>
              <Grid key={4} item xs={12} md={3} lg={1}>
                Amount:
                {/* Adjust this after rebase of table change */}
                <span>
                  {amountCurrency}
                </span>
              </Grid>
              <Grid key={5} item xs={6} md={3} lg={1}>
                Currency:
                {/* Adjust this after rebase of table change */}
                <span>
                  {currency}
                </span>
              </Grid>
              <Grid key={6} item xs={6} md={3} lg={2}>
                Reference:
                <span>
                  {reference}
                </span>
              </Grid>
              <Grid key={7} item xs={6} md={3} lg={2}>
                Counterparty:
                <span>
                  {counterparty}
                </span>
              </Grid>
              <Grid key={8} item xs={6} md={3} lg={4}>
                Description:
                <span>
                  {description}
                </span>
              </Grid>
              <Grid key={9} item xs={6} md={3} lg={1}>
                Files:
                <span>
                  {files &&
                    files.map(({id}, i) => {
                    return (<React.Fragment key={i}>
                      {i !== 0 && <span>, </span>}
                      <a
                        href={`https://drive.google.com/file/d/${id}/view?usp=sharing`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        #{i + 1}
                      </a>
                    </React.Fragment>)
                  })}
                </span>
              </Grid>
              <Grid key={10} item container xs={12}>
                <Grid key={'title'} item xs={12} md={3} lg={3}>Centers (CC/SubCC/Location/CA/CT/JiraID):</Grid>
                {Object.keys(centerSplit).map((k) => (
                  <Grid key={k} item xs={12} md={3} lg={5}>
                    <span className={classes.span}>
                      {k}: {centerSplit[k]}
                      &nbsp;{transaction && currency}
                    </span>
                  </Grid>
                ))}
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </GridList>
      <Typography>
        <i>Note: center split amounts are in account currency.</i>
      </Typography>
    </Box>
    <Toast
      message={notification.text}
      onClose={clearNotification}
      severity={notification.severity}
    />
  </>)
}

HistoryPage.propTypes = {
  data: PropTypes.object,
  forceFetch: PropTypes.func,
  setWithUserChooser: PropTypes.func,
}

export {HistoryPage}
