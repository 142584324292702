import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  listHolder: {
    'height': '100%',
    'display': 'block',
    '&>div': {
      'textAlign': 'left',
      'whiteSpace': 'nowrap',
      'borderBottom': '1px solid rgba(220, 220, 220, 1)',
      'borderLeft': '1px solid rgba(220, 220, 220, 1)',
      'borderRight': '1px solid rgba(220, 220, 220, 1)',
      'padding': theme.spacing(1),
      '&:first-child': {
        borderTop: '1px solid rgba(220, 220, 220, 1)',
      },
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
      '&>div>div': {
        'padding': 4,
        'fontSize': 14,
        'overflow': 'hidden',
        '&>span:first-child': {
          fontWeight: 'bold',
          marginLeft: '5px',
        },
      },
    },
  },
}))

const GridList = (props) => {
  const classes = useStyles()
  return (<Grid className={classes.listHolder} container justifyContent="center">
    {props.children}
  </Grid>)
}

GridList.propTypes = {
  children: PropTypes.node,
}

export {GridList}
