import React from 'react'
import {Button, Tooltip} from '@material-ui/core'
import {Link} from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import HistoryIcon from '@material-ui/icons/History'
import PairingDashboard from '@material-ui/icons/OfflinePin'
import {Header} from './Header'
import PropTypes from 'prop-types'

const pages = [
  {
    link: '/home',
    icon: <HomeIcon />,
    label: 'Home',
    tooltip: 'Add transaction details',
  },
  {
    link: '/history',
    icon: <HistoryIcon />,
    label: 'Recent invoices',
    tooltip: 'View transactions receipts',
  },
  {
    link: '/finance-dashboard',
    icon: <PairingDashboard />,
    label: 'Pairing Dashboard',
    tooltip: 'View finance pairing dashboard',
  },
]

export const renderPageButtons = (whatToExclude) => {
  if (!Array.isArray(whatToExclude)) whatToExclude = [whatToExclude]
  whatToExclude = whatToExclude.filter((x) => (typeof x === 'string'))

  const pageButtons = pages.map( (page) => {
    const isButtonDisabled = whatToExclude.includes(page.link)

    return (
      <Tooltip title={page.tooltip} arrow key={`${page.link}-button`}>
          <Button
            disabled={isButtonDisabled}
            startIcon={page.icon}
            color="primary"
            component={Link}
            to={page.link}
            style={{margin: '8px'}}// Adding margin to the buttons
          >
          {page.label}
        </Button>
        </Tooltip>
      )
  })

  return pageButtons
}

const PageSwitcherHeader = (props) => {
  const {exclude, children} = props
  return (
    <Header>
      <div />
      {children}
      {exclude !== '*' && (<span>{renderPageButtons(exclude)}</span>)}
    </Header>
  )
}

PageSwitcherHeader.propTypes = {
  exclude: PropTypes.any,
  children: PropTypes.node,
}

export {PageSwitcherHeader}
