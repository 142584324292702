import axios from 'axios'
import $tr from '@card-statements/common/transactionReviews'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const makeDataRequest = async (inNameOf) => {
  const query = new URLSearchParams()
  if (inNameOf) query.append('inNameOf', inNameOf)
  const requestUrl = `/api/data?${query.toString()}`
  let {data} = await axios.get(requestUrl)
  while (data === null) {
    sleep(500)
    data = (await axios.get(requestUrl)).data
  }
  data.loadedInNameOf = inNameOf
  return data
}

export const loadData = async (inNameOf) => {
  let result = null
  let data = {
    success: false,
    error: 'Loading data from server failed',
  }
  const inNameOfStored = localStorage.getItem('user')
  inNameOf = inNameOf || inNameOfStored
  data = await makeDataRequest(inNameOf)
  if (data.success === false && data.code === 'NO_ACCESS') {
    result = data
    data = await makeDataRequest()
  }
  localStorage.setItem('user', data.inNameOf)
  return [data, result]
}

export const sendStatementFormData = async (
  unit,
  centers,
  glAccounts,
  taxable,
  reinvoicing,
  reinvoicingProject,
  note,
  files,
  uuid,
  cardholder,
  accountCurrency,
  card,
  bank,
  bankAccount,
  legalEntity,
) => {
  const formData = new FormData()
  const json = JSON.stringify({
    unit,
    centers,
    glAccounts,
    taxable,
    reinvoicing,
    reinvoicingProject,
    note,
    uuid,
    cardholder,
    accountCurrency,
    card,
    bank,
    bankAccount,
    legalEntity,
  })
  formData.append('jsonData', json)
  files.forEach((file) => {
    formData.append('files', file)
  })

  return await axios.post('/api/upload-statement', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

function formatAxiosError(e) {
  // https://axios-http.com/docs/handling_errors
  if (e.response) return `${e.response.status}: ${e.response.message}`
  if (e.request)  return e.toJSON() // probably should be shortened
  return e.message
}

export const rejectStatement = async (pairingId, transactionUuid, reason) => {
  try {
    const data = {
      id: pairingId,
      transactionUuid,
      reason,
    }
    const options = {headers: {'Content-Type': 'application/json'}}
    const res = await axios.post('/api/reject-pairing', data, options)
    return res.data ?? {success: false, error: `${res.status}: ${res.statusText}`}
  } catch (e) {
    return {success: false, error: formatAxiosError(e)}
  }
}

export const markExportableTransaction = async (row, exportable, processedBy) => {
  try {
    row[$tr.exportable] = exportable
    row[$tr.processedBy] = processedBy
    const res = await axios.post(`/api/update-transaction-exportable-status/${row[$tr.id]}`, {exportable, processedBy})
    return res.data ?? {success: false, error: `${res.status}: ${res.statusText}`}
  } catch (e) {
    return {success: false, error: formatAxiosError(e)}
  }
}

export const exportTransactionsToDrive = async () => {
  try {
    const res = await axios.post('/api/export-transactions')
    return res.data
  } catch (e) {
    return {success: false, error: formatAxiosError(e)}
  }
}

export const downloadTransactionsZip = async (url, filename) => {
  try {
    const res = await axios.get(url, {responseType: 'arraybuffer'})
    const objectUrl = window.URL.createObjectURL(new Blob([res.data]))

    const link = document.createElement('a')
    link.href = objectUrl
    link.download = filename

    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

    return {success: true}
  } catch (e) {
    return {success: false, error: formatAxiosError(e)}
  }
}
