const _ = require('lodash')

module.exports = _.reduce([
  'id',
  'transaction_uuid',
  'createdAt',
  'cardholder',
  'data',
  'files',
  'entity',
  'exportable',
  'processed_by',
  'center_split',
  'note',
  'taxable',
], (a, v, k) => {a[v] = k; return a}, {})
